import * as React from 'react'
import { Button } from '@chakra-ui/react'
import DuckPage from '../../components/duckPage'
import { navigate } from 'gatsby'

const BookingLinkedPage = ({ id, teamId, topText }) => (
	<DuckPage
		theme="light"
		title={
			<>
				Create A <br />
				New Team
			</>
		}
		topText={topText}
		subtitle="Before you play you’ll need to create a team for your group. If you want to split up into multiple teams within your group, you can add more teams in the ‘Groups’ tab on the dashboard at any time."
		primaryBtn={
			<Button
				variant="primary"
				onClick={() => {
					navigate(`/groups/${id}/team/${teamId}/update-team/`)
				}}
			>
				Create Team
			</Button>
		}
	/>
)

export default BookingLinkedPage
