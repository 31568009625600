import * as React from 'react'
import Layout from '../../../../../layout'
import BookingLinkedPage from '../../../../../pagesComponents/groups/booking-linked'

const BookingLinked = ({ id, teamId }) => {
	return (
		<Layout theme="light" title="Booking Linked">
			<BookingLinkedPage
				id={id}
				teamId={teamId}
				topText="Your booking is now linked with your account!"
			/>
		</Layout>
	)
}

export default BookingLinked
